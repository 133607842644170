import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
  useRef,
} from "react";
import { createPortal } from "react-dom";

// Constants for positions
const POSITIONS = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right",
};

// Default values for props
const DEFAULT_DELAY = 0;
const DEFAULT_OFFSET = 12;

// Context for managing tooltip state
const TooltipContext = createContext(null);

// Calculate the best position based on available space
const calculatePosition = (
  triggerRect,
  tooltipRect,
  preferredPosition,
  offset = DEFAULT_OFFSET
) => {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  const space = {
    top: triggerRect.top,
    bottom: viewportHeight - triggerRect.bottom,
    left: triggerRect.left,
    right: viewportWidth - triggerRect.right,
  };

  const required = {
    vertical: tooltipRect.height + offset,
    horizontal: tooltipRect.width + offset,
  };

  const hasSpace = {
    top: space.top >= required.vertical,
    bottom: space.bottom >= required.vertical,
    left: space.left >= required.horizontal,
    right: space.right >= required.horizontal,
  };

  if (hasSpace[preferredPosition]) {
    return preferredPosition;
  }

  const alternatives = {
    top: ["bottom", "right", "left"],
    bottom: ["top", "right", "left"],
    left: ["right", "top", "bottom"],
    right: ["left", "top", "bottom"],
  };

  return (
    alternatives[preferredPosition].find((pos) => hasSpace[pos]) ||
    preferredPosition
  );
};

// Get coordinates for tooltip and arrow based on position
const getPositionData = (
  triggerRect,
  tooltipRect,
  position,
  offset = DEFAULT_OFFSET
) => {
  let tooltipX = 0;
  let tooltipY = 0;
  let arrowOffset = null;

  const triggerCenter = {
    x: triggerRect.left + triggerRect.width / 2,
    y: triggerRect.top + triggerRect.height / 2,
  };

  switch (position) {
    case POSITIONS.TOP:
      tooltipX = triggerCenter.x - tooltipRect.width / 2;
      tooltipY = triggerRect.top - tooltipRect.height - offset;
      break;
    case POSITIONS.BOTTOM:
      tooltipX = triggerCenter.x - tooltipRect.width / 2;
      tooltipY = triggerRect.bottom + offset;
      break;
    case POSITIONS.LEFT:
      tooltipX = triggerRect.left - tooltipRect.width - offset;
      tooltipY = triggerCenter.y - tooltipRect.height / 2;
      break;
    case POSITIONS.RIGHT:
      tooltipX = triggerRect.right + offset;
      tooltipY = triggerCenter.y - tooltipRect.height / 2;
      break;
  }

  // Calculate how much we need to adjust the tooltip to keep it in viewport
  const minX = 8;
  const maxX = window.innerWidth - tooltipRect.width - 8;
  const minY = 8;
  const maxY = window.innerHeight - tooltipRect.height - 8;

  const originalX = tooltipX;
  const originalY = tooltipY;

  tooltipX = Math.max(minX, Math.min(tooltipX, maxX));
  tooltipY = Math.max(minY, Math.min(tooltipY, maxY));

  // Calculate arrow offset based on tooltip adjustment
  if (position === POSITIONS.TOP || position === POSITIONS.BOTTOM) {
    const xDiff = originalX - tooltipX;
    arrowOffset = {
      left: `calc(50% + ${xDiff}px)`,
      marginLeft: "-5px", // Half of the arrow width
      transform: "rotate(45deg)",
    };
  } else {
    const yDiff = originalY - tooltipY;
    arrowOffset = {
      top: `calc(50% + ${yDiff}px)`,
      marginTop: "-5px", // Half of the arrow height
      transform: "rotate(45deg)",
    };
  }

  return { tooltipX, tooltipY, arrowOffset };
};

// Client-side only portal component
const ClientOnlyPortal = ({ children }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted ? createPortal(children, document.body) : null;
};

const TooltipArrow = ({ position, offset }) => {
  // Base styles for the arrow
  const baseStyles = {
    width: "10px",
    height: "10px",
    backgroundColor: "rgb(17, 24, 39)", // Matches bg-gray-900
    position: "absolute",
  };

  // Position-specific styles
  const arrowStyles = {
    [POSITIONS.TOP]: {
      bottom: "-5px",
      borderBottomRightRadius: "3px",
    },
    [POSITIONS.BOTTOM]: {
      top: "-5px",
      borderTopLeftRadius: "3px",
    },
    [POSITIONS.LEFT]: {
      right: "-5px",
      borderTopRightRadius: "3px",
    },
    [POSITIONS.RIGHT]: {
      left: "-5px",
      borderBottomLeftRadius: "3px",
    },
  };

  return (
    <div
      className="shadow-sm"
      style={{
        ...baseStyles,
        ...arrowStyles[position],
        ...offset,
      }}
    />
  );
};

// Updated Tooltip content component with animations
const TooltipContent = ({
  content,
  triggerRect,
  preferredPosition,
  offset,
}) => {
  const tooltipRef = useRef(null);
  const [position, setPosition] = useState(preferredPosition);
  const [positionData, setPositionData] = useState({
    tooltipX: 0,
    tooltipY: 0,
    arrowOffset: null,
  });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const bestPosition = calculatePosition(
        triggerRect,
        tooltipRect,
        preferredPosition,
        offset
      );
      setPosition(bestPosition);
      setPositionData(
        getPositionData(triggerRect, tooltipRect, bestPosition, offset)
      );
      // Trigger animation after positioning
      requestAnimationFrame(() => setIsVisible(true));
    }

    // Cleanup animation on unmount
    return () => setIsVisible(false);
  }, [triggerRect, preferredPosition, offset]);

  return (
    <div
      ref={tooltipRef}
      className={`absolute rounded bg-gray-900 px-4 py-2 text-sm leading-tight text-white shadow-lg backdrop-blur-sm transition-opacity
                  duration-150 delay-75 ease-in-out motion-reduce:transition-none
                  ${isVisible ? "opacity-100" : "opacity-0"}`}
      style={{
        transform: `translate(${positionData.tooltipX}px, ${positionData.tooltipY}px)`,
        maxWidth: "200px",
      }}
    >
      {content}
      <TooltipArrow position={position} offset={positionData.arrowOffset} />
    </div>
  );
};
// TooltipRoot component with animation timing for unmounting
export const TooltipRoot = ({ children }) => {
  const [tooltips, setTooltips] = useState(new Map());
  const timeoutRef = useRef(new Map());
  const showTimeoutRef = useRef(new Map());

  const registerTooltip = useCallback(
    (id, content, triggerRect, preferredPosition, delay, offset) => {
      // Clear any existing timeouts for this tooltip
      if (timeoutRef.current.has(id)) {
        clearTimeout(timeoutRef.current.get(id));
        timeoutRef.current.delete(id);
      }
      if (showTimeoutRef.current.has(id)) {
        clearTimeout(showTimeoutRef.current.get(id));
        showTimeoutRef.current.delete(id);
      }

      // Add delay for showing tooltip
      const showTimeout = setTimeout(() => {
        setTooltips((prev) =>
          new Map(prev).set(id, {
            content,
            triggerRect,
            preferredPosition,
            offset,
          })
        );
        showTimeoutRef.current.delete(id);
      }, delay);

      showTimeoutRef.current.set(id, showTimeout);
    },
    []
  );

  const unregisterTooltip = useCallback((id) => {
    // Clear show timeout if it exists
    if (showTimeoutRef.current.has(id)) {
      clearTimeout(showTimeoutRef.current.get(id));
      showTimeoutRef.current.delete(id);
    }

    // Add slight delay to allow fade-out animation
    const timeout = setTimeout(() => {
      setTooltips((prev) => {
        const newMap = new Map(prev);
        newMap.delete(id);
        return newMap;
      });
      timeoutRef.current.delete(id);
    }, 100); // Match duration-100 from the transition

    timeoutRef.current.set(id, timeout);
  }, []);

  // Cleanup timeouts on unmount
  useEffect(() => {
    return () => {
      timeoutRef.current.forEach((timeout) => clearTimeout(timeout));
      showTimeoutRef.current.forEach((timeout) => clearTimeout(timeout));
    };
  }, []);

  return (
    <TooltipContext.Provider value={{ registerTooltip, unregisterTooltip }}>
      {children}
      <ClientOnlyPortal>
        <div className="pointer-events-none fixed left-0 top-0 z-50">
          {Array.from(tooltips.entries()).map(
            ([id, { content, triggerRect, preferredPosition, offset }]) => (
              <TooltipContent
                key={id}
                content={content}
                triggerRect={triggerRect}
                preferredPosition={preferredPosition}
                offset={offset}
              />
            )
          )}
        </div>
      </ClientOnlyPortal>
    </TooltipContext.Provider>
  );
};

// Hook for creating tooltip triggers with new props
export const useTooltip = (
  content,
  {
    preferredPosition = POSITIONS.TOP,
    delay = DEFAULT_DELAY,
    offset = DEFAULT_OFFSET,
  } = {}
) => {
  const [id] = useState(() => Math.random().toString(36).substr(2, 9));
  const context = useContext(TooltipContext);

  if (!context) {
    throw new Error("useTooltip must be used within a TooltipRoot");
  }

  const { registerTooltip, unregisterTooltip } = context;

  const handleMouseEnter = useCallback(
    (e) => {
      const rect = e.currentTarget.getBoundingClientRect();
      registerTooltip(id, content, rect, preferredPosition, delay, offset);
    },
    [id, content, preferredPosition, delay, offset, registerTooltip]
  );

  const handleMouseLeave = useCallback(() => {
    unregisterTooltip(id);
  }, [id, unregisterTooltip]);

  return {
    tooltipProps: {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    },
  };
};
