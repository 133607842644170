import { configureStore } from "@reduxjs/toolkit";
import storage from "./reduxStorage";
import { persistReducer, persistStore, createMigrate } from "redux-persist";
import { combineReducers } from "redux";
import thunk from "redux-thunk";

import metadata from "../../constants/metadata.json";
import authReducer from "./auth/authSlice";
import appReducer from "./app/appSlice";
import accountingSlice from "./accounting/accountingSlice";

let MIGRATION_DEBUG = false;
let storeVersion = metadata.storeVersion;
if (process.env.NEXT_PUBLIC_NODE_ENV == "development") {
  // in the development we always want to use the latest version
  storeVersion = -1;
  MIGRATION_DEBUG = true;
}

const migrations = {
  [1]: (state) => {
    return {
      auth: state.auth,
      app: state.app,
      accounting: state.accounting,
    };
  },
};

const persistConfig = {
  key: "saritrahub",
  storage,
  version: storeVersion,
};

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  accounting: accountingSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NEXT_PUBLIC_NODE_ENV !== "production",
  middleware: [thunk],
  migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG }),
});

export const persistor = persistStore(store);
