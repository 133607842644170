import Progress from "@badrap/bar-of-progress";
import Router from "next/router";

const progress = new Progress({
  size: 3,
  color: "black",
  className: "bar-of-progress z-20 shadow-sm ",
  delay: 100,
});

// this fixes safari jumping to the bottom of the page
// when closing the search modal using the `esc` key
if (typeof window !== "undefined") {
  progress.start();
  progress.finish();
}

Router.events.on("routeChangeStart", () => progress.start());
Router.events.on("routeChangeComplete", () => progress.finish());
Router.events.on("routeChangeError", () => progress.finish());

const ProgressBar = () => {
  return "";
};

export { ProgressBar, progress };
