import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    side_bar_open: true,
    dimensions: {
      height: 0,
      width: 0,
    },
  },
  reducers: {
    setSideBarOpen: (state, action) => {
      state.side_bar_open = action.payload;
    },
    setDimensions: (state, action) => {
      state.dimensions = {
        height: action.payload.height,
        width: action.payload.width,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSideBarOpen, setDimensions } = appSlice.actions;

export default appSlice.reducer;
