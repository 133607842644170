import { createSlice } from "@reduxjs/toolkit";

export const accountingSlice = createSlice({
  name: "accounting",
  initialState: {
    upload_csv_modal_open: false,
    upload_account_statement_modal_open: false,
    add_transaction_modal_open: false,
    selected_statement_upload_account: {
      name: "",
      in: 0,
      out: 0,
      starting_balance: 0,
      in_count: 0,
      out_count: 0,
      year: 0,
      month: 0,
    },
    selected_balances: [],
    selected_year: new Date().getFullYear(),
    selected_month: new Date().getMonth(),
    clients: [],
    selected_client: null,
    show_client_edit_modal: false,
  },
  reducers: {
    setUploadCsvModalOpen: (state, action) => {
      state.upload_csv_modal_open = action.payload;
    },
    setSelectedBalances: (state, action) => {
      state.selected_balances = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.selected_month = action.payload.month;
      state.selected_year = action.payload.year;
    },
    setSelectedYear: (state, action) => {
      state.selected_year = action.payload;
    },
    setUploadAccountStatementModalOpen: (state, action) => {
      state.upload_account_statement_modal_open = action.payload;
    },
    setAddTransactionModalOpen: (state, action) => {
      state.add_transaction_modal_open = action.payload;
    },
    setSelectedBalanceAccount: (state, action) => {
      state.selected_statement_upload_account = action.payload;
    },
    setClientEditModalOpen: (state, action) => {
      state.show_client_edit_modal = action.payload;
    },
    setSelectedClient: (state, action) => {
      state.selected_client = action.payload;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUploadCsvModalOpen,
  setSelectedBalances,
  setSelectedDate,
  setSelectedYear,
  setUploadAccountStatementModalOpen,
  setSelectedBalanceAccount,
  setClientEditModalOpen,
  setAddTransactionModalOpen,
  setSelectedClient,
  setClients,
} = accountingSlice.actions;

export default accountingSlice.reducer;
