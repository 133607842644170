import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    is_logged_in: false,
    last_sign_in: null,
    web_token: {
      token: null,
    },
  },
  reducers: {
    setLastSignIn: (state, action) => {
      state.last_sign_in = action.payload;
    },
    setLoginState: (state, action) => {
      state.is_logged_in = action.payload;
      if(action.payload) state.last_sign_in = new Date();
    },
    setAuthToken: (state, action) => {
      state.web_token = action.payload.web_token;
      //state.last_sign_in = new Date();
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLastSignIn, setLoginState, setAuthToken } = authSlice.actions;

export default authSlice.reducer;
