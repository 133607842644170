import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setDimensions } from "../features/app/appSlice";

const ResizeListener = () => {
  const dispatch = useDispatch();
  const handleResize = () => {
  
    dispatch(
      setDimensions({ width: window.innerWidth, height: window.innerHeight })
    );
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);
};

module.exports = ResizeListener;
